<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        The image below shows 5 overlaid spectra of the same red dye. The differences in the spectra
        are created by dilution.
      </p>

      <p class="mb-4 pl-6">
        <v-img style="max-width: 593px" src="/img/assignments/uciWavelengthGraph.jpg" />
      </p>

      <p class="mb-n3">a) Which spectrum is unreliable?</p>

      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mb-0 ml-6">
        <v-radio
          v-for="option in options1"
          :key="'pt-1-' + option.value"
          class="my-1 ml-3"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3 pt-3">b) Why?</p>

      <v-radio-group v-model="inputs.input2" :disabled="!allowEditing" class="mb-0 ml-6">
        <v-radio
          v-for="option in options2"
          :key="'pt-2-' + option.value"
          class="my-1 ml-3"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'ChemUCI1LCQ2S2Q6',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
      },
      options1: [
        {text: 'Red line spectrum', value: 'red'},
        {text: 'Orange line spectrum', value: 'orange'},
        {text: 'Green line spectrum', value: 'green'},
        {text: 'Blue line spectrum', value: 'blue'},
        {text: 'Purple line spectrum', value: 'purple'},
        {text: 'All are reliable', value: 'all'},
        {text: 'None are reliable', value: 'none'},
      ],
      options2: [
        {
          text: 'The jagged line at high absorbance indicates the detector is saturated',
          value: 'saturated',
        },
        {text: 'The low absorbance is difficult to read', value: 'cantRead'},
        {text: 'The absorbance peak is not broad enough', value: 'notBroad'},
        {text: 'The absorbance peak is too broad', value: 'tooBroad'},
      ],
    };
  },
};
</script>
<style scoped></style>
